<template>
  <div>
    <Loading v-if="loading" />
    <v-container>
      <v-row class="mt-2">
        <v-col cols="12" class="py-0">
          <div class="d-flex justify-space-between">
            <h2 class="text-secondary header">แก้ไขข้อมูลส่วนตัว</h2>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-row>
      <v-col cols="12">
        <v-card color="px-6 py-6 mt-8">
          <v-form ref="form" @submit.prevent="onUpdateData()">
            <v-text-field
              :rules="[notEmptyRules]"
              disabled
              v-model="form.username"
              label="ชื่อผู้ใช้"
            />
            <div v-if="!changePassword">
              <v-btn
                color="green"
                dark
                class="mt-4 mb-8"
                @click="changePassword = !changePassword"
                ><v-icon>mdi-key-variant</v-icon>เปลี่ยนรหัสผ่าน</v-btn
              >
            </div>
            <div v-if="changePassword">
              <v-text-field
                v-model="form.password"
                label="รหัสผ่าน"
                :rules="[notEmptyRules]"
                :type="isOpenPassword ? 'text' : 'password'"
                :append-icon="isOpenPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="isOpenPassword = !isOpenPassword"
              />
              <v-text-field
                v-model="confirmPassword"
                label="ยืนยันรหัสผ่าน"
                :rules="[notEmptyRules]"
                :type="isOpenPassword ? 'text' : 'password'"
                :append-icon="isOpenPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="isOpenPasswordConfirm = !isOpenPasswordConfirm"
              />
            </div>
            <v-text-field
              :rules="[notEmptyRules]"
              v-model="form.firstname"
              label="ชื่อจริง"
            />
            <v-text-field
              :rules="[notEmptyRules]"
              v-model="form.lastname"
              label="นามสกุล"
            />
            <div class="text-right mt-4">
              <v-btn color="green" dark class="font-weight-bold" type="submit"
                >บันทึก</v-btn
              >
            </div>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Loading from "@/components/Loading.vue";
import axios from "../../utils/axios.js";
export default {
  components: {
    Loading,
  },
  computed: {
    ...mapGetters({
      info: "User/info",
    }),
    notEmptyRules() {
      return (value) => !!value || "กรุณากรอกข้อมูล";
    },
    branchItems() {
      return [
        { text: "ตรัง", value: "trang" },
        { text: "สาทร", value: "sathorn" },
        { text: "ทั้งหมด", value: "all" },
      ];
    },
  },
  data() {
    return {
      loading: false,
      changePassword: false,
      isOpenPassword: false,
      isOpenPasswordConfirm: false,
      confirmPassword: "",
      form: {
        username: "",
        password: "",
        firstname: "",
        lastname: "",
        branch: "",
      },
    };
  },
  mounted() {
    this.getAuthMemberById();
  },
  methods: {
    async getAuthMemberById() {
      try {
        if (this.info && this.info.profile.id) {
          const {
            data: { data },
          } = await axios.get(`/auth/${this.info.profile.id}/id`);
          this.form = data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async onUpdateData() {
      try {
        const valid = this.$refs.form.validate();
        if (valid) {
          this.loading = true;
          if (this.info && this.info.profile.id) {
            await axios.patch(`/auth/${this.info.profile.id}/id`, this.form);
          }
          this.alertSuccess("ดำเนินการสำเร็จ");
        }
      } catch (error) {
        this.alertError("เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง");
      } finally {
        this.loading = false;
      }
    },
    alertSuccess(text) {
      this.$toast.success(text, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: false,
        icon: true,
        rtl: false,
      });
    },
    alertError(text) {
      this.$toast.error(text, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: false,
        icon: true,
        rtl: false,
      });
    },
  },
};
</script>
